<script>
import SelectPage from '@/js/components/select-page.vue'
import APISTATE from '@/js/models/apistate'
import '@/css/select-page.css'

import {INIT_CUSTOMER_LIST, SET_ACTIVE_CUSTOMER, SET_ACTIVE_CUSTOMER_NAME,} from '@/js/store/types'

export default {
    name: 'CustomerSelector',
    components: {
        SelectPage,
    },
    props: ['purl', 'isReadOnly', 'cartPageUrl'],
    data() {
        return {
            selectedCustomer: null,
            model: null,
            errorMessage: null,
            columns: [
                {title: 'Id', data: 'ErpId'},
                {title: 'Name', data: 'Name'},
            ],
            searchFields: ['ErpId', 'Name'],
            pagingToken: null,
            customerSearchRows: null,
        }
    },
    computed: {
        apiLoading() {
            return (
                this.$store.getters.customerApiState == APISTATE.LOADING
                || this.$store.getters.customerApiState == APISTATE.INIT
            )
        },
        apiLoaded() {
            return this.$store.getters.customerApiState == APISTATE.LOADED
        },
        apiError() {
            return this.$store.getters.customerApiState == APISTATE.ERROR
        },
        isCustomerDistributor() {
            return this.$store.getters.isCustomerDistributor
        },
        customerApiState() {
            return this.$store.getters.customerApiState
        },
        availableCustomers() {
            return this.$store.getters.availableCustomers
        },
        activeCustomer() {
            return this.$store.getters.activeCustomer
        },
        activeCustomerName() {
            return this.$store.getters.activeCustomerName
        },
        isInternalEmployee() {
            return this.$store.getters.isInternalEmployee
        },
        store() {
            return this.$store.state
        },
    },
    mounted() {
        // this.purl = scriptElement === null ? [] : JSON.parse(scriptElement.innerHTML).purl;
        this.$store.dispatch(INIT_CUSTOMER_LIST, this.purl)
    },
    methods: {
        onNewSearch(e) {
            this.pagingToken = null
        },
        doDataLoad(obj, data, queryParams) {
            // todo: this function is called multiple times
            if (this.purl) {
                const params = {
                    customerLookup: queryParams.Name,
                    pageNumber: queryParams.pageNumber,
                }

                if (this.pagingToken)
                    params.pagingToken = this.pagingToken

                return this.$http.get(data, {params})
            } else {
                return new Promise((resolve) => {
                    resolve()
                })
            }
        },
        formatResult(response) {
            if (response.data) {
                if (response.data.Status && response.data.Status == 'failed') {
                    this.errorMessage = response.data.Message
                    this.isError = true
                    this.$toastify.toastError(this.errorMessage)
                } else {
                    this.isError = false
                    const data = response.data.Data.data

                    if (data.PageInfo)
                        this.pagingToken = data.PageInfo.PagingToken

                    if (data.PageInfo.TotalRecords) {
                        // Total records not populated on pages > 1
                        this.customerSearchRows = data.PageInfo.TotalRecords
                    }

                    // Make sure the active customer is in the list so we can display a value
                    if (this.activeCustomer) {
                        const selectedIndex = data.AvailableCustomers.findIndex(
                            x => x.ErpId === this.activeCustomer,
                        )
                        if (selectedIndex === -1) {
                            data.AvailableCustomers.unshift({
                                ErpId: this.activeCustomer,
                                Name: this.activeCustomerName,
                            })
                        }
                    }

                    return {
                        list: data.AvailableCustomers,
                        totalRows: this.customerSearchRows,
                    }
                }
            } else {
                this.isError = true
                this.errorMessage
                    = response.Message && response.Message.length
                    ? response.Message
                    : 'Failed to search for customers'
                this.$toastify.toastError(this.errorMessage)
            }

            return {}
        },
        // TODO: get already selected from local storage
        setActiveCustomer(e) {
            const customerObject = e[0]

            if (customerObject && customerObject.ErpId !== this.activeCustomer) {
                this.$http
                    .post(`${this.purl}/uc/checkout/customer`, {
                        SelectedErpCustomerId: customerObject.ErpId,
                        SelectedCustomerName: customerObject.Name,
                    })
                    .then(function (response) {
                        if (response.data) {
                            if (response.data.Status && response.data.Status == 'failed') {
                                this.errorMessage = response.data.Message
                                this.isError = true
                                this.$toastify.toastError(this.errorMessage)
                            } else {
                                this.isError = false
                                this.errorMessage = ''
                                const data = response.data.Data
                                e.Value = data.customerId
                                if (this.activeCustomer)
                                    this.$toastify.toastInfo('Customer selected')

                                this.$store.dispatch(SET_ACTIVE_CUSTOMER, customerObject.ErpId)
                                this.$store.dispatch(SET_ACTIVE_CUSTOMER_NAME, customerObject.Name)

                                if (customerManager)
                                    customerManager.syncActiveCustomer(false)

                                const event = new CustomEvent('customerpicked', {
                                    detail: customerObject.ErpId,
                                })
                                window.dispatchEvent(event)
                            }
                        } else {
                            this.errorMessage = response.Message
                            this.$toastify.toastError(this.errorMessage)
                            this.isError = true
                        }
                    })
            }
        },
        showRow(row) {
            return `${row.ErpId}: ${row.Name}`
        },
    },
}
</script>

<template>
    <div>
        <template v-if="isCustomerDistributor">
            <h5 v-if="!isReadOnly">
                Active Customer
            </h5>
            <template v-if="apiLoading">
                Loading Customers...
            </template>
            <template v-if="apiLoaded">
                <template v-if="isReadOnly">
                    <template v-if="cartPageUrl">
                        <span class="customer-display">Your current customer is: {{ activeCustomer }}: {{ activeCustomerName }} (<a
                            :href="cartPageUrl">change</a>)</span>
                    </template>
                    <template v-else>
                        <span class="customer-display">Your current customer is: {{ activeCustomer }}: {{ activeCustomerName }}</span>
                    </template>
                </template>
                <template v-else>
                    <template v-if="isInternalEmployee">
                        <SelectPage
                            v-model="activeCustomer"
                            :data="`${purl}/uc/checkout/customerlookup`"
                            :data-load="doDataLoad"
                            :result-format="formatResult"
                            :search-fields="searchFields"
                            :show-field="showRow"
                            :tb-columns="columns"
                            key-field="ErpId"
                            language="en"
                            page-size="20"
                            placeholder="Select Customer"
                            @newsearch="onNewSearch"
                            @values="setActiveCustomer"
                        />
                    </template>
                    <template v-else>
                        <SelectPage
                            v-model="activeCustomer"
                            :data="availableCustomers"
                            :search-fields="searchFields"
                            :show-field="showRow"
                            :tb-columns="columns"
                            key-field="ErpId"
                            language="en"
                            placeholder="Select Customer"
                            @values="setActiveCustomer"
                        />
                    </template>
                </template>
            </template>
            <template v-if="apiError">
                error {{ apiError }}
            </template>
        </template>
    </div>
</template>

<style scoped>
@import "../../css/select-page.css";
</style>
