export default {
    cn: { // Chinese
      next: '下一页',
      prev: '上一页',
      first: '首页',
      last: '尾页',
      close_btn: '关闭 (Esc键)',
      loading: '读取中...',
      page_info: '第 page_num/page_count 页( 共 row_count 条记录 )',
      not_found: '无查询结果',
      server_error: '连接到服务器时发生错误！',
      clear: '清除内容',
      select_all: '选择当前页项目',
      unselect_all: '取消选择当前页项目',
      clear_all: '清除全部已选择项目',
      max_selected: '最多只能选择 max_selected_limit 个项目',
      placeholder: '请选择一个项目',
      items_selected: '已选择 selected_count 个项目'
    },
    en: { // English
      next: 'Next page',
      prev: 'Previous page',
      first: 'First page',
      last: 'Last page',
      close_btn: 'Close (Esc key)',
      loading: 'loading...',
      page_info: 'Page page_num/page_count ( row_count records )',
      not_found: 'not found',
      server_error: 'An error occurred while connecting to server.',
      clear: 'Clear content',
      select_all: 'Select current page',
      unselect_all: 'Clear current page',
      clear_all: 'Clear all selected',
      max_selected: 'You can only select up to max_selected_limit items',
      placeholder: 'Select an option',
      items_selected: 'selected_count items selected'
    },
    ja: { // Japanese
      next: '次へ',
      prev: '前へ',
      first: '最初のページへ',
      last: '最後のページへ',
      close_btn: '閉じる (Esc キー)',
      loading: '読み込み中...',
      page_info: 'page_num/page_count 件 ( 全 row_count つ記録 )',
      not_found: '(0 件)',
      server_error: 'サーバとの通信でエラーが発生しました。',
      clear: 'コンテンツをクリアする',
      select_all: '当ページを選びます',
      unselect_all: '移して当ページを割ります',
      clear_all: '選択した項目をクリアする',
      max_selected: '最多で max_selected_limit のプロジェクトを選ぶことしかできません',
      placeholder: 'プロジェクトを選択してください',
      items_selected: 'selected_count アイテムが選択されました'
    },
    ar: { // Arabic
      next: 'التالي',
      prev: 'السابق',
      first: 'الاول',
      last: 'الأخير',
      close_btn: 'إغلاق عند الضغط على زر (Esc)',
      loading: 'جاري التحميل ...',
      page_info: 'صفحة page_num/page_count ( row_count سجلات )',
      not_found: 'لا يوجد نتائج',
      server_error: 'لقد حدث خطأ أثناء الإتصال بالخادم.',
      clear: 'محو المحتوى',
      select_all: 'تحديد الصفحة الحالية',
      unselect_all: 'إلغاء تحديد الصفحة الحالية',
      clear_all: 'إلغاء التحديد',
      max_selected: 'يمكنك فقط تحديد (max_selected_limit) عناصر',
      placeholder: 'رجاء حدد الخيار',
      items_selected: 'تم تحديد (selected_count) عناصر'
    },
    es: { // Spanish
      next: 'Siguiente página',
      prev: 'Pagina anterior',
      first: 'Primera página',
      last: 'última página',
      close_btn: 'Cerrar (tecla Esc)',
      loading: 'Cargando ...',
      page_info: 'Página page_num/page_count ( row_count registros )',
      not_found: 'no encontrado',
      server_error: 'Ocurrió un error al conectarse al servidor.',
      clear: 'Borrar contenido',
      select_all: 'Seleccionar página actual',
      unselect_all: 'Borrar la página actual',
      clear_all: 'Borrar todo lo seleccionado',
      max_selected: 'Solo puedes seleccionar hasta max_selected_limit items',
      placeholder: 'Seleccione una opción',
      items_selected: 'selected_count items Seleccionado'
    },
    de: { // German
      next: 'Nächste Seite',
      prev: 'Vorherige Seite',
      first: 'Erste Seite',
      last: 'Letzte Seite',
      close_btn: 'Schließen (Esc)',
      loading: 'Lädt...',
      page_info: 'Seite page_num/page_count ( row_count Einträge )',
      not_found: 'Nicht gefunden',
      server_error: 'Bei der Verbindung zum Server ist ein Fehler aufgetreten.',
      clear: 'Inhalt löschen',
      select_all: 'Aktuelle Seite auswählen',
      unselect_all: 'Auswahl der aktuellen Seite aufheben',
      clear_all: 'Alle ausgewählten löschen',
      max_selected: 'Sie können nur bis zu max_selected_limit Elemente auswählen',
      placeholder: 'Wählen',
      items_selected: 'selected_count Elemente ausgewählt'
    },
    ro: { // Romanian
      next: 'Pagina următoare',
      prev: 'Pagina precedentă',
      first: 'Prima pagină',
      last: 'Ultima pagină',
      close_btn: 'Închide (tasta Esc)',
      loading: 'se încarcă...',
      page_info: 'Pagina page_num/page_count ( row_count înregistrări )',
      not_found: 'nu a fost găsit',
      server_error: 'O eroare a apărut în timpul conectării la server.',
      clear: 'Șterge conținutul',
      select_all: 'Selectează pagina curentă',
      unselect_all: 'Deselectează pagina curentă',
      clear_all: 'Șterge înregistrările selectate',
      max_selected: 'Poți selecta până la max_selected_limit înregistrări',
      placeholder: 'Selectează o înregistrare',
      items_selected: 'selected_count înregistrări selectate'
    },
    ru: { // Russian
      next: 'Вперед',
      prev: 'Назад',
      first: 'В начало',
      last: 'В конец',
      close_btn: 'Закрыть (Esc)',
      loading: 'загрузка...',
      page_info: 'Стр. page_num/page_count (всего - row_count)',
      not_found: 'Нет данных',
      server_error: 'Ошибка загрузки данных',
      clear: 'Очистить',
      select_all: 'Выбрать все',
      unselect_all: 'Сбросить все',
      clear_all: 'Очистить выбранное',
      max_selected: 'Нельзя выбрать более max_selected_limit значений',
      placeholder: 'Выберите значение',
      items_selected: 'selected_count - выбрано'
    },
    fr: { // French
      next: 'Page suivante',
      prev: 'Page précédente',
      first: 'Première page',
      last: 'Dernière page',
      close_btn: 'Fermer (touche Esc)',
      loading: 'chargement...',
      page_info: 'Page page_num/page_count ( row_count lignes )',
      not_found: 'Aucun résultat',
      server_error: 'Une erreur s\'est produite lors de la connexion avec le serveur.',
      clear: 'Effacer',
      select_all: 'Sélectionner tous les éléments de la page',
      unselect_all: 'Déselectionner tous les élements de la page',
      clear_all: 'Tout déselectionner',
      max_selected: 'Vous ne pouvez pas sélectionner plus de max_selected_limit élements',
      placeholder: 'Sélectionnez une option',
      items_selected: 'selected_count éléments sélectionnés'
    },
    pt_br: { // Portuguese-Brazil
      next: 'Página seguinte',
      prev: 'Página anterior',
      first: 'Primera página',
      last: 'Última página',
      close_btn: 'Encerrar (tecla Esc)',
      loading: 'Carregando ...',
      page_info: 'Página page_num/page_count ( row_count registros )',
      not_found: 'não encontrado',
      server_error: 'Ocorreu um erro ao conectar com o servidor.',
      clear: 'Apagar conteúdo',
      select_all: 'Selecionar página atual',
      unselect_all: 'Apagar página atual',
      clear_all: 'Apagar itens selecionados',
      max_selected: 'Máximo permitido max_selected_limit itens',
      placeholder: 'Selecione uma opção',
      items_selected: 'selected_count itens selecionados'
    },
    pl: { // Polish
      next: 'Następna',
      prev: 'Poprzednia',
      first: 'Pierwsza',
      last: 'Ostatnia',
      close_btn: 'Zamknij (ESC)',
      loading: 'Ładowanie...',
      page_info: 'Strona page_num/page_count ( row_count rekordów )',
      not_found: 'Nic nie znaleziono',
      server_error: 'Wystąpił problem w połączeniu z serwerem.',
      clear: 'Wyczyść',
      select_all: 'Zaznacz wszystko',
      unselect_all: 'Usuń zaznaczenie',
      clear_all: 'Usuń wszystkie zaznaczone',
      max_selected: 'Możesz zaznaczyć maksymalnie max_selected_limit',
      placeholder: 'Wybierz z listy',
      items_selected: 'selected_count zaznaczonych'
    },
    nl: { // Dutch
      next: 'Volgende pagina',
      prev: 'Vorige pagina',
      first: 'Eerste pagina',
      last: 'Laatste pagina',
      close_btn: 'Sluiten (Esc toets)',
      loading: 'laden...',
      page_info: 'Pagina page_num/page_count ( row_count items )',
      not_found: 'Niet gevonden',
      server_error: 'Er is een fout opgetreden tijdens het verbinden met de server',
      clear: 'Wissen',
      select_all: 'Selecteer huidige pagina',
      unselect_all: 'Wis huidige pagina',
      clear_all: 'Wis selectie',
      max_selected: 'Je kunt maar max_selected_limit items selecteren',
      placeholder: 'Kies een optie',
      items_selected: 'selected_count Items geselecteerd'
    },
    tw: { // Traditional Chinese
      next: '下一頁',
      prev: '上一頁',
      first: '首頁',
      last: '尾頁',
      close_btn: '關閉 (Esc鍵)',
      loading: '讀取中...',
      page_info: '第 page_num/page_count 頁( 共 row_count 條記錄 )',
      not_found: '無查詢結果',
      server_error: '連接到服務器時發生錯誤！',
      clear: '清除內容',
      select_all: '選擇當前頁項目',
      unselect_all: '取消選擇當前頁項目',
      clear_all: '清除全部已選擇項目',
      max_selected: '最多只能選擇 max_selected_limit 個項目',
      placeholder: '請選擇一個項目',
      items_selected: '已選擇 selected_count 個項目'
    }
  }
  