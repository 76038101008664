<script>
import dropdown from 'v-dropdown'
import '@/css/select-page.css'

import data from '@/js/mixins/select-page-data'
import methods from '@/js/mixins/select-page-methods'
import util from '@/js/mixins/select-page-util'
import render from '@/js/mixins/select-page-render'

import SelectPageList from "@/js/components/select-page-list.vue"
import SelectPagePagination from "@/js/components/select-page-pagination.vue"
import SelectPageSelect from "@/js/components/select-page-select.vue"
import SelectPageTable from "@/js/components/select-page-table.vue"
import SelectPageTag from "@/js/components/select-page-tag.vue"

export default {
    name: 'selectPage',
    mixins: [data, methods, util, render],
    components: {
        'v-dropdown': dropdown,
        'sp-tag': SelectPageTag,
        'sp-select': SelectPageSelect,
        'sp-page': SelectPagePagination,
        'sp-list': SelectPageList,
        'sp-table': SelectPageTable
    },
    provide() {
        return {
            i18n: this.i18n,
            renderCell: this.renderCell,
            keyField: this.keyField,
            showField: this.showField,
            inPicked: this.inPicked,
            rtl: this.rtl
        }
    },
    mounted() {
        // set searchField when user not config
        if (!this.searchFields) {
            if (typeof this.showField === 'string') {
                this.searchColumns = new Array(this.showField)
            } else if (Array.isArray(this.showField) && this.showField.length) {
                this.searchColumns = new Array(this.showField[0].data)
            }
        } else {
            this.searchColumns = this.searchFields;
        }

        // sort data list
        this.sortList()
        this.initSelection()
    }
};

</script>